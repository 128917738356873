import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import AboutHero from "../components/aboutHero/aboutHero";
import AboutValue from "../components/aboutValue/aboutValue";

const AboutPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="About Us" />
      <AboutHero data={data.wpPage.aboutHeroEn.aboutHeroEn} />
      <AboutValue data={data.wpPage.aboutCoreValuesEn.aboutCoreValuesEn} />
    </Layout>
  );
};

export default AboutPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(slug: { eq: "about-en" }) {
      aboutHeroEn {
        aboutHeroEn {
          title
          content
          paragraphs
        }
      }
      aboutCoreValuesEn {
        aboutCoreValuesEn {
          title
          valueList {
            item1 {
              title
              content
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            item2 {
              title
              content
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            item3 {
              title
              content
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
